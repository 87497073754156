
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import GroupEntityModule from '@/store/settings/groups/entity';
import GroupModule from '@/store/settings/groups';

@Component({
  components: {
    TitleReturn,
    FormBlock,
    Loading,
  },
})
export default class GroupEntityEdit extends Form {
  isLoader = true;

  get titlePage() {
    return GroupModule.pageSettings.titleEdit;
  }

  returnBefore() {
    this.$router.push({ name: 'groups' });
  }

  async mounted() {
    await GroupEntityModule.initEdit(this.$route.params.id as string);

    this.isLoader = false;
  }
}
